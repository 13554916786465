.MuiOutlinedInput {
    border: none;
}

.MuiOutlinedInput > div {
    border: solid 1px rgba(0, 0, 0, 0.23);
    border-radius: 4px;
}

.MuiOutlinedInput > div:hover {
    border-color: rgba(0, 0, 0, 0.87) !important;
    cursor: text;
}

.MuiOutlinedInput > div.error__control, .MuiOutlinedInput > div.error__control:hover {
    border-color: rgb(244, 67, 54) !important;
}

.error__placeholder {
    color: rgb(244, 67, 54) !important;
}

.autocomplete__error .MuiFormHelperText-root {
    position: absolute;
}